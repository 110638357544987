body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Poppins", sans-serif;
  /* min-height: 100vh; */
  /* width: 100vw; */
}
/* #root > div > div >  div {
  min-height: 100vh;
} */
.dropdown-toggle-split > :nth-child(2) > :nth-child(4) {
  display: none;
}
.mrt-table > div {
  box-shadow: none;
}
.mrt-table > div > :nth-child(2) > table > thead > tr {
  background-color: #266092 !important;
}
.mrt-table
  > div
  > :nth-child(2)
  > table
  > thead
  > tr
  > th
  > div
  > :nth-child(2)
  > button,
.mrt-table
  > div
  > :nth-child(2)
  > table
  > thead
  > tr
  > th
  > div
  > div
  > :nth-child(2)
  > span
  > svg,
.mrt-table > div > :nth-child(2) > table > thead > tr > th {
  color: white !important;
  font-weight: 500 !important ;
  font-size: 15px !important;
  padding: 12px !important;
  border-right: 1px solid rgb(184, 184, 184) !important;
}
.mrt-table > div > div {
  border-radius: 8px !important ;
}
.modal-form {
  margin-top: 40px;
}
.loading-text {
  margin-top: 10px;
  font-size: 15px;
  color: #118b8f;
}
.loader {
  width: 60px;
  height: 60px;
  border-right: 3px solid #118b8f;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
  margin: 0 auto !important;

  &:before,
  &:after {
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-left: 2px solid #118b8f;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }

  &:after {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    border: 0;
    border-right: 1px solid #118b8f;
    animation: none;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.status0,
.status1 {
  background-color: rgb(189, 255, 199);
  color: rgb(136, 136, 136);
  display: inline-block;
  padding: 2px 10px 2px 10px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 6px;
  width: 95px !important;
}

.status1-filled {
  background-color: rgba(1, 134, 192, 0.47);
  color: rgb(47, 47, 47);
  display: inline-block;
  padding: 2px 10px 2px 10px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 6px;
  width: 95px !important;
}
.status1-active {
  background-color: #74d99f;
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: 2px 10px 2px 10px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 6px;
  width: 95px !important;
}
.status1-deactived {
  background-color: #ec6969;
  color: rgb(222, 217, 217);
  display: inline-block;
  padding: 2px 10px 2px 10px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 6px;
  width: 95px !important;
}
.status0 {
  color: rgb(180, 92, 92);
  background-color: rgb(255, 236, 126);
  width: 95px !important;
}
.blur-div-top-bar,
.blur-div {
  background-color: #ffffff;
  position: fixed;
  width: 94vw;
  z-index: 1000;
}
.blur-div {
  height: 1rem;
}
.blur-div-top-bar {
  height: 3rem;
  margin-left: 8rem;
}

.nav-bar,
.nav-bar-shrink {
  position: fixed;
  /* width: 80vw; */
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  background-color: #266092;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
  border-radius: 10px;
  margin: 0px 1%;
  padding-top: 8px;
  /* padding-bottom: 6px; */
}
.nav-bar {
  width: 90vw;
}
.nav-bar-shrink {
  width: 77vw;
}
@media screen and (max-width: 1040px) {
  .nav-bar {
    width: 87vw;
  }
  .nav-bar-shrink {
    width: 72vw;
  }
}

@media screen and (max-width: 850px) {
  .nav-bar {
    width: 86vw;
  }
  .nav-bar-shrink {
    width: 69vw;
  }
}
@media screen and (max-width: 700px) {
  .nav-bar {
    width: 80vw;
  }
  .nav-bar-shrink {
    width: 60vw;
  }
}
.nav-bar-btn {
  background-color: #266092;
  color: white;
  font-weight: 400;
  font-size: 17px;
}
/* .borders-bottom {
  border-bottom: 4px white solid;
} */
.tab-text-color {
  font-weight: 600;
  color: #1a1a1a;
  background-color: white;
  padding-bottom: 1vmin;
  /* border-radius: 10px; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* border-bottom-right-radius: -10px;
  border-bottom-left-radius: 10px; */
}
.form-heading {
  display: flex;
  justify-content: space-around;
  color: #0f91d2;
  font-size: 15px;
}
/* Image Field */
.upload-div {
  display: flex;
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  width: 100%;
  padding: 2px 6px 0 10px !important;
  color: #b7b5b5;
}
.image-div,
.image-div-error {
  padding-left: 5px;
  border-radius: 6px;
  height: 30px;
  border: 2px solid rgb(45, 45, 45);
}

.image-div-error {
  /* background-color: #e7e7e7; */
  color: rgb(240, 14, 14);
  border: 2px solid rgb(240, 14, 14);
}
.image-div {
  background-color: rgb(195, 195, 195);
  color: rgb(58, 58, 58);
}
.delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(152, 23, 23, 0.856);
  color: white;
  border-left: 2px white;
  cursor: pointer;
}
.upload-error .ant-upload {
  border-radius: 6px;
  border: 1px solid red;
}
/* image view after form submission or for admin */
.img-fluid {
  border: 2px solid #333333;
  border-radius: 15px;
  min-width: 90%;
  max-width: 100%;
  min-height: 150px;
  max-height: 160px;
}
.img-fluid-profile{
  border: 2px solid #333333;
  border-radius: 15px;
  min-width: 70%;
  max-width: 70%;
  min-height: 200px;
  max-height: 250px;
}
@media screen and (max-width: 575px) {
  .img-fluid{
    min-width: 70%;
    max-width: 70%;
    min-height: 200px;
    max-height: 250px;
  }
}

@media screen and (max-width: 450px) {
  .nav-bar {
    width: 76vw;
    border-radius: 3px;
    padding-top: 4px;
  }
  .nav-bar-btn {
    padding-bottom: 1px;
    padding-top: 1px;
    font-size: 2vmax;
  }
  .form-heading {
    font-size: 2.2vmax;
  }
}

.pdf-iframe {
  width: 100%;
  height: 500px;
  border: #0f91d2 2px solid;
  border-radius: 12px;
  /* background-color: #0f91d2 !important; */
}

.css-hzsuj3-MuiTableCell-root {
  font-family: "poppins" !important;
}

.MuiTableContainer-root > table > thead > tr > th,
.MuiTableContainer-root > table > tbody > tr > td {
  font-family: Poppins, sans-serif !important;
  text-align: center !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #1b5e9466 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #1b5e9466;
  border-radius: 12px;
}
.btn-ui {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: #55aeda 1px solid;
}

.btn-ui:hover {
  background-color: #0f91d2;
  color: white !important;
  transition: ease-in-out 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.ant-menu-dark .ant-menu-item-selected {
  background: #000c17 !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #214b6e !important;
}

.ant-menu-item-icon {
  font-size: "20px";
}
/* .anticon svg {
  width: 20px;
  height: 20px;
} */

/* .ant-layout-sider-children {
  overflow-y: auto !important;
  scroll-behavior: smooth;
} */

/* image field */
.image-center {
  display: flex;
  justify-content: center;
}
/* remove builtin uploaded msg */
.ant-upload-list-text {
  display: none;
}

/* Hide table header filters */
.css-1c32n2y-MuiBadge-root {
  display: none !important;
}
.css-azlldr-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}
.css-1p8dotb-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

.css-1w86f15 {
  justify-content: center !important;
}

/* Table icons space  */
.css-dermwj {
  justify-content: center !important;
}

.ant-layout .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  overflow-y: scroll;
}

/* Hide scrollbar */
.ant-layout .ant-layout-sider-children::-webkit-scrollbar {
  width: 0; /* remove scrollbar width */
  height: 0; /* remove scrollbar height */
}
