.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f0f2f5;
}
/* .loginBox {
  height: 550px;
} */
.signupBox {
  height: 580px;
}

.loginBox,
.signupBox {
  border-radius: 10px;
  width: 350px;
  padding: 20px;
  box-shadow: 13px 19px 20px 0px rgba(0, 0, 0, 0.08);
  background-color: rgb(255, 255, 255);
}
.loginForm > div > div > div > label {
  font-size: 16px !important;
  height: 38px !important;
}
.loginBox > form > div > div,
.signupBox > form > div > div {
  display: block;
}
.loginForm > div {
  margin-top: 20px !important;
}
.signupForm > div {
  margin-top: 20px !important;
}
.register {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  font-size: 15px;
}
